"use client";

import { useEffect } from "react";

function FreshchatWidget() {
  useEffect(() => {
    function initFreshChat() {
      // @ts-ignore
      if (window.fcWidget) {
        console.log("5: Initializing Freshchat");
        // @ts-ignore
        window.fcWidget.init({
          token: "cfc5e39e-65fb-48aa-a2c7-f0f46a92966c",
          host: "https://travelthru.freshchat.com",
        });
      } else {
        console.error("Freshchat widget is not available");
      }
    }

    function initialize(i: any, t: any) {
      console.log("4: Initializing script");
      var e;
      if (i.getElementById(t)) {
        initFreshChat();
      } else {
        e = i.createElement("script");
        e.id = t;
        e.async = true;
        e.src = "https://travelthru.freshchat.com/js/widget.js";
        e.onload = () => {
          console.log("Script loaded");
          initFreshChat();
        };
        e.onerror = () => {
          console.error("Failed to load Freshchat script");
        };
        i.head.appendChild(e);
        console.log("Script appended");
      }
    }

    initialize(document, "Freshchat-js-sdk");

  }, []);

  return null;
}

export default FreshchatWidget;